@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
@import "https://fonts.cdnfonts.com/css/pinky-cupid";
@font-face {
  font-family: lg;
  src: url("lg.f8edcdbb.woff2") format("woff2"), url("lg.253cca28.ttf") format("truetype"), url("lg.f801b1a8.woff") format("woff"), url("lg.c9542252.svg#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.lg-icon {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: lg !important;
}

.lg-container {
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.lg-next, .lg-prev {
  color: #999;
  cursor: pointer;
  z-index: 1084;
  background-color: #00000073;
  border: none;
  border-radius: 2px;
  outline: none;
  margin-top: -10px;
  padding: 8px 10px 9px;
  font-size: 22px;
  display: block;
  position: absolute;
  top: 50%;
}

.lg-next.disabled, .lg-prev.disabled {
  cursor: default;
  opacity: 0 !important;
}

.lg-next:hover:not(.disabled), .lg-prev:hover:not(.disabled) {
  color: #fff;
}

.lg-single-item .lg-next, .lg-single-item .lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}

.lg-next:before {
  content: "";
}

.lg-prev {
  left: 20px;
}

.lg-prev:after {
  content: "";
}

@keyframes lg-right-end {
  0% {
    left: 0;
  }

  50% {
    left: -30px;
  }

  100% {
    left: 0;
  }
}

@keyframes lg-left-end {
  0% {
    left: 0;
  }

  50% {
    left: 30px;
  }

  100% {
    left: 0;
  }
}

.lg-outer.lg-right-end .lg-object {
  animation: .3s lg-right-end;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  animation: .3s lg-left-end;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, #0000, #0006);
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  text-align: center;
  will-change: color;
  -o-transition: color .2s linear;
  box-shadow: none;
  background: none;
  border: none;
  outline: none;
  width: 50px;
  height: 47px;
  padding: 10px 0;
  font-size: 24px;
  line-height: 27px;
  transition: color .2s linear;
  text-decoration: none !important;
}

.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: "";
}

.lg-toolbar .lg-maximize {
  font-size: 22px;
}

.lg-toolbar .lg-maximize:after {
  content: "";
}

.lg-toolbar .lg-download:after {
  content: "";
}

.lg-sub-html {
  color: #eee;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -o-transition: opacity .2s ease-out 0s;
  padding: 10px 40px;
  font-size: 16px;
  transition: opacity .2s ease-out;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.lg-sub-html p {
  margin: 5px 0 0;
  font-size: 12px;
}

.lg-sub-html a {
  color: inherit;
}

.lg-sub-html a:hover {
  text-decoration: underline;
}

.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(#0000, #0009);
}

.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.lg-error-msg {
  color: #999;
  font-size: 14px;
}

.lg-counter {
  color: #999;
  vertical-align: middle;
  height: 47px;
  padding-top: 12px;
  padding-left: 20px;
  font-size: 16px;
  display: inline-block;
}

.lg-closing .lg-toolbar, .lg-closing .lg-prev, .lg-closing .lg-next, .lg-closing .lg-sub-html {
  opacity: 0;
  -webkit-transition: -webkit-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  -moz-transition: -moz-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  -o-transition: -o-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  transition: transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap, body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  will-change: transform, opacity;
  transform: scale3d(.5, .5, .5);
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  transition: transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap, body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: 3px;
  outline: 1px dashed #fff9;
}

.lg-toolbar .lg-icon:focus-visible {
  outline-offset: -5px;
  border-radius: 8px;
}

.lg-group:after {
  content: "";
  clear: both;
  display: table;
}

.lg-container {
  outline: none;
  display: none;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-overlay-open {
  overflow: hidden;
}

.lg-toolbar, .lg-prev, .lg-next, .lg-pager-outer, .lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
}

.lg-show-in .lg-toolbar, .lg-show-in .lg-prev, .lg-show-in .lg-next, .lg-show-in .lg-pager-outer, .lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}

.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  z-index: 1050;
  text-align: left;
  opacity: .001;
  will-change: auto;
  -o-transition: opacity .15s ease 0s;
  outline: none;
  width: 100%;
  height: 100%;
  transition: opacity .15s;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.lg-outer * {
  box-sizing: border-box;
}

.lg-outer.lg-zoom-from-image, .lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  opacity: 1;
  transition-duration: 0s !important;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-content {
  position: absolute;
  inset: 0;
}

.lg-outer .lg-inner {
  -o-transition: opacity 0s;
  white-space: nowrap;
  width: 100%;
  transition: opacity;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.lg-outer .lg-item {
  display: none !important;
}

.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: url("loading.9e763859.gif") center no-repeat;
}

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.lg-outer .lg-img-wrap {
  white-space: nowrap;
  font-size: 0;
  position: absolute;
  inset: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-object {
  vertical-align: middle;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: inline-block;
  position: relative;
}

.lg-outer .lg-empty-html.lg-sub-html, .lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}

.lg-outer.lg-hide-download .lg-download {
  opacity: .75;
  pointer-events: none;
}

.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  opacity: 1;
  transform: translate3d(0, 0%, 0);
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity .2s ease-out .15s;
}

.lg-backdrop {
  z-index: 1040;
  opacity: 0;
  will-change: auto;
  -o-transition: opacity .333s ease-in 0s;
  background-color: #000;
  transition: opacity .333s ease-in;
  position: fixed;
  inset: 0;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -o-transition: none 0s ease 0s !important;
  transition: none !important;
}

.lg-css3.lg-use-css3 .lg-item {
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -o-transition: opacity .1s ease 0s;
  transition: opacity .1s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(.175, .885, .32, 1.275);
  -o-transition: -o-transform 1s cubic-bezier(.175, .885, .32, 1.275) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(.175, .885, .32, 1.275);
  transition: transform 1s cubic-bezier(.175, .885, .32, 1.275);
}

.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1);
  transition: transform 1s cubic-bezier(0, 0, .25, 1);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  transition: transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
}

.lg-container {
  display: none;
}

.lg-container.lg-show {
  display: block;
}

.lg-container.lg-dragging-vertical .lg-backdrop {
  transition-duration: 0s !important;
}

.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  opacity: 1;
  transition-duration: 0s !important;
}

.lg-inline .lg-backdrop, .lg-inline .lg-outer {
  position: absolute;
}

.lg-inline .lg-backdrop {
  z-index: 1;
}

.lg-inline .lg-outer {
  z-index: 2;
}

.lg-inline .lg-maximize:after {
  content: "";
}

.lg-components {
  will-change: transform;
  -webkit-transition: -webkit-transform .35s ease-out;
  -o-transition: -o-transform .35s ease-out 0s;
  -moz-transition: -moz-transform .35s ease-out;
  z-index: 1080;
  transition: transform .35s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 100%, 0);
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  transition-duration: 0s !important;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform .5s cubic-bezier(.12, .415, .01, 1.19);
  -o-transition: -o-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s;
  -moz-transition: -moz-transform .5s cubic-bezier(.12, .415, .01, 1.19);
  transition: transform .5s cubic-bezier(.12, .415, .01, 1.19);
}

.lg-outer.lg-use-transition-for-zoom.lg-zoom-drag-transition .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform .8s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform .8s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .8s cubic-bezier(0, 0, .25, 1);
  transition: transform .8s cubic-bezier(0, 0, .25, 1);
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img {
  backface-visibility: hidden;
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform .5s cubic-bezier(.12, .415, .01, 1.19), opacity .15s !important;
  -o-transition: -o-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s, opacity .15s !important;
  -moz-transition: -moz-transform .5s cubic-bezier(.12, .415, .01, 1.19), opacity .15s !important;
  transition: transform .5s cubic-bezier(.12, .415, .01, 1.19), opacity .15s !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.no-transition, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.no-transition {
  transition: none !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition {
  max-width: none !important;
  max-height: none !important;
  top: 50% !important;
  left: 50% !important;
  transform: scale3d(1, 1, 1)translate3d(-50%, -50%, 0) !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition-x, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition-x {
  max-width: none !important;
  max-height: none !important;
  top: 0 !important;
  left: 50% !important;
  transform: scale3d(1, 1, 1)translate3d(-50%, 0, 0) !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition-y, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition-y {
  max-width: none !important;
  max-height: none !important;
  top: 50% !important;
  left: 0% !important;
  transform: scale3d(1, 1, 1)translate3d(0, -50%, 0) !important;
}

.lg-icon.lg-zoom-in:after {
  content: "";
}

.lg-actual-size .lg-icon.lg-zoom-in {
  opacity: .5;
  pointer-events: none;
}

.lg-icon.lg-actual-size {
  font-size: 20px;
}

.lg-icon.lg-actual-size:after {
  content: "";
}

.lg-icon.lg-zoom-out {
  opacity: .5;
  pointer-events: none;
}

.lg-icon.lg-zoom-out:after {
  content: "";
}

.lg-zoomed .lg-icon.lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer[data-lg-slide-type="video"] .lg-zoom-in, .lg-outer[data-lg-slide-type="video"] .lg-actual-size, .lg-outer[data-lg-slide-type="video"] .lg-zoom-out, .lg-outer[data-lg-slide-type="iframe"] .lg-zoom-in, .lg-outer[data-lg-slide-type="iframe"] .lg-actual-size, .lg-outer[data-lg-slide-type="iframe"] .lg-zoom-out, .lg-outer.lg-first-slide-loading .lg-zoom-in, .lg-outer.lg-first-slide-loading .lg-actual-size, .lg-outer.lg-first-slide-loading .lg-zoom-out {
  opacity: .75;
  pointer-events: none;
}

.lg-outer .lg-thumb-outer {
  float: left;
  background-color: #0d0a0a;
  width: 100%;
  max-height: 350px;
  overflow: hidden;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb, .lg-outer .lg-thumb-outer.lg-rebuilding-thumbnails .lg-thumb {
  transition-duration: 0s !important;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-middle {
  text-align: center;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-left {
  text-align: left;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-right {
  text-align: right;
}

.lg-outer.lg-single-item .lg-thumb-outer {
  display: none;
}

.lg-outer .lg-thumb {
  vertical-align: middle;
  height: 100%;
  margin-bottom: -5px;
  padding: 5px 0;
  display: inline-block;
}

@media (width >= 768px) {
  .lg-outer .lg-thumb {
    padding: 10px 0;
  }
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  will-change: border-color;
  border-radius: 2px;
  height: 100%;
  margin-bottom: 5px;
  overflow: hidden;
}

@media (width >= 768px) {
  .lg-outer .lg-thumb-item {
    -o-transition: border-color .25s ease;
    border: 2px solid #fff;
    border-radius: 4px;
    transition: border-color .25s;
  }
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer .lg-toggle-thumb:after {
  content: "";
}

.lg-outer.lg-animate-thumb .lg-thumb {
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
}

h1 {
  font-family: Pink Cupid, sans-serif;
  font-weight: bold;
}

h2 {
  font-family: Pink Cupid, sans-serif;
}

p {
  font-family: Montserrat, sans-serif;
}

#initial {
  max-height: 1000px;
  transition: transform 1s cubic-bezier(.68, -.55, .27, 1.55), opacity .5s ease-in-out, max-height 2s ease-in-out;
  overflow: hidden;
}

.slideUp {
  opacity: 0;
  max-height: 0;
  transform: translateY(-100%);
}

.container__primary {
  max-width: 1344px;
  margin: 0 auto;
}

@media only screen and (width <= 1407px) and (width >= 1216px) {
  .container__primary {
    max-width: 85%;
  }
}

@media only screen and (width <= 1215px) {
  .container__primary {
    max-width: 90%;
  }
}

@media only screen and (width <= 768px) {
  .container__primary {
    max-width: 100%;
  }
}

.section-container {
  align-items: center;
  display: flex;
  position: relative;
}

.is-outfit {
  color: #194b77;
  font-weight: 600;
  font-family: Outfit, sans-serif !important;
}

.is-hover-btn:hover {
  filter: brightness(.9);
}

.is-desktop {
  display: block;
}

@media only screen and (width <= 1023px) {
  .is-desktop {
    display: none !important;
  }
}

.is-mobile {
  display: block;
}

@media only screen and (width >= 1024px) {
  .is-mobile {
    display: none !important;
  }
}

.cover-container {
  justify-content: center;
  align-items: top;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.cover-container img {
  object-fit: cover;
}

@media only screen and (width <= 1024px) {
  .cover-container {
    flex-direction: column-reverse;
    display: flex;
  }
}

.corona {
  width: 134px;
}

@media only screen and (width <= 1024px) {
  .corona {
    width: 75px;
    top: -193% !important;
  }
}

.profile-picture-dsktp {
  display: block;
}

@media only screen and (width <= 1024px) {
  .profile-picture-dsktp {
    display: none;
  }
}

.profile-picture-mobile {
  display: block;
}

@media only screen and (width >= 1023px) {
  .profile-picture-mobile {
    display: none;
  }
}

.vector-tl {
  width: 300px;
}

@media only screen and (width <= 1200px) {
  .vector-tl {
    width: 50%;
  }
}

.vector-tr {
  width: 187px;
}

@media only screen and (width <= 1200px) {
  .vector-tr {
    width: 60px;
  }
}

.vector-bl {
  width: 11vw;
}

@media only screen and (width <= 1200px) {
  .vector-bl {
    width: 65px;
  }
}

.vector-br {
  width: 13vw;
}

@media only screen and (width <= 1200px) {
  .vector-br {
    width: 76px;
  }
}

.cover-section-info {
  flex-direction: column;
}

@media only screen and (width <= 1024px) {
  .cover-section-info {
    margin-bottom: -22%;
  }
}

.title {
  letter-spacing: 0;
  text-align: center;
  font-size: 63px;
  font-weight: 400;
  line-height: 70px;
}

@media only screen and (width >= 1200px) {
  .title {
    font-size: 119px;
    line-height: 124px;
  }
}

@media only screen and (width <= 1024px) {
  .initial-content {
    grid-template-columns: unset;
    flex-direction: column;
    gap: 23px;
    display: flex;
  }
}

.my-parents-container {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media only screen and (width < 880px) {
  .my-parents-container {
    grid-template-columns: 1fr;
    display: grid;
  }
}

.my-parents-container .left-side {
  flex-direction: column;
  display: flex;
}

.my-parents-container .left-side .my-parents-text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.my-parents-container .left-side .my-parents-text p {
  width: 60%;
  margin-bottom: 36px;
}

.my-parents-container .left-side .my-parents-sm-photos {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.my-parents-container .left-side .my-parents-sm-photos img {
  width: 55%;
  margin-top: 0;
}

@media only screen and (width < 880px) {
  .my-parents-container .left-side .my-parents-sm-photos {
    display: none;
  }
}

.my-parents-container .right-side {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.my-parents-container .right-side img {
  width: 100%;
}

.my-parents-container .right-side .img-text {
  display: none;
}

@media only screen and (width < 880px) {
  .my-parents-container .right-side .img-text {
    display: inline;
  }

  .my-parents-container .right-side .only-img {
    display: none;
  }
}

.one-img-container {
  justify-content: center;
  margin-top: 0;
  display: flex;
  position: relative;
}

.one-img-container img {
  margin: auto;
}

@media only screen and (width < 550px) {
  .one-img-container img {
    max-width: 100%;
    margin-left: 0;
  }
}

.thanks-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.thanks-container .thanks-text {
  flex-direction: row;
  align-items: center;
  gap: 15px;
  display: flex;
}

@media only screen and (width <= 1024px) {
  .thanks-container .thanks-text svg {
    width: 45px;
  }
}

.thanks-container h2 {
  text-align: center;
  color: #ff5e7f;
  margin: 0;
  font-family: Pinky Cupid, sans-serif;
  font-size: 6em;
  font-weight: 400;
}

@media only screen and (width <= 1024px) {
  .thanks-container h2 {
    font-size: 2rem;
  }
}

.initial-content {
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  height: 100%;
  display: grid;
}

@media only screen and (width <= 1023px) {
  .initial-content {
    grid-template-columns: unset;
    flex-direction: column;
    gap: 23px;
    display: flex;
  }
}

.text-message {
  margin-left: 50px;
}

@media only screen and (width <= 1024px) {
  .text-message {
    margin-left: 0;
  }
}

.subtitle {
  letter-spacing: 0;
  text-align: center;
  margin-top: -10px;
  margin-left: 0;
  font-size: 48px;
  font-weight: 400;
  line-height: 70px;
}

@media only screen and (width >= 1023px) {
  .subtitle {
    margin-top: -45px;
    margin-left: 30%;
    font-size: 80px;
    line-height: 124px;
  }
}

@media (width <= 769px) {
  .content-date {
    height: 86% !important;
  }
}

.date-info-container {
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  height: auto;
  display: grid;
}

@media only screen and (width <= 769px) {
  .date-info-container {
    grid-template-columns: unset;
    flex-direction: column;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    height: auto;
    display: flex;
  }
}

.date-info-container .map-img {
  width: 150px;
}

@media only screen and (width <= 1023px) {
  .date-info-container .map-img {
    width: 81px;
  }
}

@media only screen and (width <= 992px) {
  .date-info-container #openModalMap {
    width: 196px !important;
    height: 50px !important;
  }
}

@media (width <= 1024px) {
  .date-info-container .content-location {
    gap: 8px !important;
  }
}

.text-h3 {
  text-align: center;
  color: #ff5e7f;
  margin: 0;
  font-family: Pinky Cupid, sans-serif;
  font-size: 6em;
  font-weight: 400;
}

@media only screen and (width <= 1024px) {
  .text-h3 {
    font-size: 3rem;
  }
}

.count-down {
  max-width: 436px;
}

@media only screen and (width <= 600px) {
  .count-down {
    width: 100%;
    max-width: 100%;
  }
}

.circle {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

@media only screen and (width >= 1024px) {
  .circle {
    width: 79px;
    height: 79px;
  }
}

.text-countdown {
  width: 400px;
}

@media only screen and (width <= 1024px) {
  .text-countdown {
    width: 300px;
  }
}

.content-btns {
  gap: 53px;
}

@media only screen and (width <= 1024px) {
  .content-btns {
    gap: 20px;
  }
}

.text-circle {
  font-size: 30px !important;
}

@media only screen and (width <= 1024px) {
  .text-circle {
    font-size: 18px !important;
  }
}

@media (width < 600px) {
  .sun {
    width: 100px;
  }

  .fernanda {
    width: 100%;
    margin-top: 25px;
  }
}

@media (width < 1200px) {
  .invitacion_primary {
    background-color: #fef5ff;
  }

  .container_vector .vector-tl {
    display: none;
  }

  .invitacion {
    display: none !important;
  }
}

.dedicatoria {
  padding: 15px;
}

@media (width < 1200px) {
  .parents, .elements {
    flex-direction: column;
  }

  .only-time {
    width: 60%;
  }

  .sun-count {
    display: none;
  }

  .vector_count {
    height: 100%;
  }

  #btnMap {
    width: 50% !important;
    height: 40px !important;
  }

  .content_map {
    height: 75vh !important;
    min-height: 75vh !important;
  }

  .last_content {
    padding: 0 !important;
  }
}

.map-img {
  max-width: 800px;
}

@media only screen and (width <= 1023px) {
  .map-img {
    width: 100%;
  }
}

/*# sourceMappingURL=index.47f9b77c.css.map */
